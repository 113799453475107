import React from 'react'
import "../assets/services.css"
import morgueImg from "../gallery/morgue.jpg"
import catholic_coffin from "../gallery/catholic_coffin.jpg"

const Services = () => {
  return (
    <div id="services" className="servicesParentDiv">
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/05/hnd-1.jpeg" alt="" />
            <div>
                <p>Hindu Funeral Services</p>
            </div>
        </div>
        {/* <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/05/sm.png" alt="" />
            <div>
                <p>Antim Sanskar Saman</p>
            </div>
        </div> */}
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/03/3-1.png" alt="" />
            <div>
                <p>Antayashti Samagri (Items For Funeral Rites)</p>
            </div>
        </div>
        
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/03/shradh_1475068018-1024x600.jpeg" alt="" />
            <div>
                <p>Bhataji/Pandit 10th,12th,13th Shradha Vidhi</p>
            </div>
        </div>
        
        {/* <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/05/pan.jpg" alt="" />
            <div>
                <p>Pandit</p>
            </div>
        </div> */}
        
        
        {/* <div className="slide-up">
            <img src={morgueImg} alt="" />
            <div>
                <p>Morgue Service</p>
            </div>
        </div> */}
        {/* <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/03/2-1024x576.jpg" alt="" />
            <div>
                <p>Catholic Funeral</p>
            </div>
        </div> */}
        <div className="slide-up">
            <img src={catholic_coffin} alt="" />
            <div>
                <p>Catholic Coffin</p>
            </div>
        </div>
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/05/coff.jpg" alt="" />
            <div>
                <p>Catholic Funeral</p>
            </div>
        </div>
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/05/amb.jpg" alt="" />
            <div>
                <p>Ambulance Service & Team</p>
            </div>
        </div>
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/05/m1.jpeg" alt="" />
            <div>
                <p>Funeral Team</p>
            </div>
        </div>
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/03/Mortuary-Service.jpg" alt="" />
            <div>
                <p>Mortuary Service</p>
            </div>
        </div>
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/05/frr.jpg" alt="" />
            <div>
                <p>Dead Body Freezer/Dead Body Dispatch</p>
            </div>
        </div>
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/03/moruge.png" alt="" />
            <div>
                <p>Dead Body Embalming / Shifting & Morgue Service</p>
            </div>
        </div>
        
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/05/hatrse.png" alt="" />
            <div>
                <p>Vimukti Vahan (Ambulance/Hearse)</p>
            </div>
        </div>
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/05/WhatsApp-Image-2022-05-18-at-12.01.32-PM.jpeg" alt="" />
            <div>
                <p>Rathyatra</p>
            </div>
        </div>
        
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/05/condolence-message-1.jpg" alt="" />
            <div>
                <p>Arrangement For Condolence Meeting</p>
            </div>
        </div>
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/05/communication-at-workplace-1.jpg" alt="" />
            <div>
                <p>Legal Help</p>
            </div>
        </div>
        <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/03/maxresdefault-1024x576.jpg" alt="" />
            <div>
                <p>Registration At Cemetery & Death Certificate</p>
            </div>
        </div>
        
        {/* <div className="slide-up">
            <img src="https://funeralsservices.in/wp-content/uploads/2022/03/1024px-Immersion_Of_Asthi_Into_River_Ganga_-_Ramkrishnapur_Ghat_-_Howrah_20170627160455-1024x601.jpg" alt="" />
            <div>
                <p>Immersion Of Asthi</p>
            </div>
        </div> */}
        
    </div>
  )
}

export default Services