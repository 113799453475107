import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "../assets/mainnav.css"


const MainNav = () => {


  return (
    <div className="mainNavDiv">

    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/">
            <div className="logoDiv" title="Antim-Moksh By Kripa Funeral Services, Mumbai">
                <div>
                  <div className="Brand1">ANTIM</div>
                  <div className="Brand2">MOKSH</div>
                </div>
                <div className="brand3Div">
                  <span className="brand3">Kripa Funerals</span>
                
                    <a style={{textDecoration:'none'}} href="tel:09653231181">
                      <span className="directCall">+91 9653231181</span>
                    </a>
                  
                </div>
            </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" >
          <Nav  className="me-auto ">
          </Nav>
          <Nav>
            <Nav.Link href="#intro">Home</Nav.Link>
            <Nav.Link href="#services">Services</Nav.Link>
            <Nav.Link href="#footer">Contact us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  )
}

export default MainNav