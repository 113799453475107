import React from "react";
import "../assets/footer.css";
import whatsAppLogo from "../gallery/whatsAppLogo.png"

const Footer = () => {
  return (
    <>
      <div id="footer" className="mainFooter">
        <div className="gridDiv">
          <div className="childGrid">
            <h3>Company</h3>
            <br />
            <p>About</p>
            <p>Blog</p>
            <p>Customers</p>
            <p>Legal</p>
          </div>
          <div className="childGrid">
            <h3>Our Services</h3>
            <br />
            <p>Funeral Sevices</p>
            <p>Ambulance Services</p>
            <p>Antim Sanskar Samagri</p>
            <p>Pandit Services</p>
            <p>Mourning Services</p>
            <p>Mortuary Services</p>
            <p>Embalming Services</p>
            <p>Repatriation Services</p>
            <p>Cremation Services</p>
            <p>Morgue Services</p>
          </div>
          <div className="childGrid">
            <h3>Connect with Us On</h3>
            <br />
            <p>Facebook</p>
            <p>YouTube</p>
            <p>Instagram</p>
            <p>WhatsApp</p>
          </div>
          <div className="childGrid">
            <h3>Contact</h3>
            <br />
            <a className="externalLinks" href="mailto:kharatrohit001@gmail.com">
              <p>Email: kharatrohit001@gmail.com</p>
            </a>
            <a className="externalLinks" href="tel:09653231181">
              <p>Phone : +91 9653231181</p>
            </a>
            <a className="externalLinks" href="tel:09653231181">
              <p>Phone : +91 8108891173</p>
            </a>
          </div>
        </div>
        <div className="whatsAppLogoDiv" title="9653231181">
        <a href="https://wa.me/message/G22HWUM5GGVNC1">
          <img className="whatsAppLogo"  src={whatsAppLogo} alt="" />
        </a>
      </div>
      </div>
    </>
  );
};

export default Footer;
