import React from "react";
import Carousel from "react-bootstrap/Carousel";
import styles from "./carouselpics.module.css";
import cremation from "../../gallery/cremation.jpg"
import { useNavigate } from "react-router-dom";

const CarouselPics = () => {
//   const navigate = useNavigate();
  return (
    <div className={styles.carouselParent}>
      <div className={styles.waviy}>
        <span style={{ "--i": 1 }}>O</span>
        <span style={{ "--i": 2 }}>U</span>
        <span style={{ "--i": 3, marginRight: "30px" }}>R</span>
        <span style={{ "--i": 4 }}>S</span>
        <span style={{ "--i": 5 }}>E</span>
        <span style={{ "--i": 6 }}>R</span>
        <span style={{ "--i": 7 }}>V</span>
        <span style={{ "--i": 8 }}>I</span>
        <span style={{ "--i": 9 }}>C</span>
        <span style={{ "--i": 10 }}>E</span>
        <span style={{ "--i": 10 }}>S</span>
      </div>

      <Carousel >
        <Carousel.Item className={styles.itemsInCarousel} interval={2500}>
          <img className="d-block" src="https://funeralsservices.in/wp-content/uploads/2022/05/1.jpg" alt="Funeral" />
          <Carousel.Caption className={styles.caption}>
            <div>
              <h6 className={styles.otherProdsHeading}>Funeral</h6>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className={styles.itemsInCarousel} interval={2500}>
          <img
            className="d-block"
            // src="https://funeralsservices.in/wp-content/uploads/2022/05/03.jpg"
            src="https://media.gettyimages.com/id/1218736764/photo/fire-that-is-burning-the-death-man-according-the-indian-tradition.jpg?s=612x612&w=0&k=20&c=50_DekLpYe6-NZem9viAZvOgBSrMiCIIDZ31ODwdQlA="
            alt=" Cremation"
          />
          <Carousel.Caption className={styles.caption}>
            <div >
              <h6 className={styles.otherProdsHeading}>Cremation</h6>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className={styles.itemsInCarousel} interval={2500}>
          <img className="d-block" src="https://funeralsservices.in/wp-content/uploads/2022/05/4.jpg" alt="Ambulance" />
          <Carousel.Caption className={styles.caption}>
            <div>
              <h6 className={styles.otherProdsHeading}>Ambulance</h6>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselPics;
