import logo from './logo.svg';
import './App.css';
import MainNav from './components/MainNav';
import Footer from './components/Footer';
import Intro from './components/Intro';
import CarouselPics from './components/carousel/CarouselPics';
import Services from './components/Services';

function App() {
  return (
    <div className="App">
      <MainNav />
      <Intro />
      <CarouselPics />
      <Services />
      <Footer />
    </div>
  );
}

export default App;
