import React from 'react'
import "../assets/intro.css"

const Intro = () => {
  return (
    <div id="intro" className="introContainer">
        <div className="introTextDiv">
            <h3 className="introText slide-up-intro">We manage and arrange for the body to be moved from the hospital to home and then to the crematorium. We also provide all the material for the last rites & arrange for priests too. We ANTIM MOKSH ensures that the entire process is hassle-free, dignified and easy for the family.</h3>
        </div>
    </div>
  )
}

export default Intro